@font-face {
	font-family: Montebello;
	src: url(../fonts/Montebello-Rounded.otf);
}


/* GLOBAL */
/* html, body {
	overscroll-behavior-y: none;
} */

body {
	padding: 0;
	margin: 0;
	background-color: #302a4a;
	color: #fff;
	position:relative;
	overflow-y:auto;
}

h1, h2, h3, h4, h5{
	font-family: 'Montebello', sans-serif;
	color: #fc8121;
}

p{
	font-family: "Source Sans Pro", "Helvetica", sans-serif;
	font-size: 1.25em;
    line-height: 1.25em;
    letter-spacing: .05em;
    font-weight: 300;
}

h1{
	font-size: 3.25em;
    line-height: 1.25em;
}

a {
	color: #5dcab3;
	text-decoration: none;
}

a:hover,
a:active {
	color: #47ecc9;
}

.w3-row {
	display: flex;
	align-items: top;
}

.w3-container {
	flex: 1;
}

.center-container {
	align-items: center;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-content: center;
	justify-content: center;
}

.content-container {
	min-height: 600px;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	align-content: center;
	align-items: center;
	justify-content: center;
	position: relative;
}

.content-div {
	width: 70%;
	max-width: 900px;
	padding: 3% 0;
	z-index: 3;
}

.show-on-mobile{
	height:0;
	display: none;
	visibility: hidden;
}

.img-border{
	z-index: 2;
	overflow: hidden;
    width: 100%;
	position:absolute;
    min-height: 25px;
}

.img-border img{
	width: 100%;
	position: absolute;
}

.img-border-top, .img-border-top img{
	top: 0;
}

.img-border-bottom, .img-border-bottom img{
	bottom: 0;
}

.parallax-container{
	position: relative;
}

.layer {
	min-height: 98vh;
}

.w3-row .w3-col.m6:first-of-type{
	padding-right:5%;
}

.w3-row .w3-col.m6:last-of-type{
	padding-left:5%;
}
/* END GLOBAL */

/* HEADER */
#header {
	height: 98vh;
	overflow: hidden;
	position: relative;
	max-height: 1080px;
}

#header-mobile {
	display: none;
	background: url("../img/Header_Mobile.png") no-repeat
		center/cover;
	height: 600px;
}


#header .layer{
    min-height: 1080px;
    background-position: bottom center;
	background-size: auto;
	background-repeat: no-repeat;
	width: 100%;
	position: fixed;
	z-index: -1;
}

#header .layer-bg {
	background-color: #000004;
	background-image: url("../img/Header_Layer_00.png");
    background-position: top -200px center;
}

#header .layer-1 {
	background-image: url("../img/Header_Layer_01.png");
    background-position: center;
    background-size: auto 100%;
}

#header .layer-2 {
	background-image: url("../img/Header_Layer_02.png");
	background-position: top -150px center;
    background-size: auto 110%;
}

#header .layer-logo {
	background-image: url("../img/Header_Logo.png");
	background-position: top 110px center;
    background-size: 500px;
}

#header .layer-3 {
	background-image: url("../img/Header_Layer_03.png");
	background-position: bottom -150px center;
}

#header .layer-4 {
	background-image: url("../img/Header_Layer_04.png");
	background-position: center;
}

#header-bottom{
    height: 20%;
   /*  background-image: url(""); */
}

#header-logo{
	background-image: url(../img/Header_Logo.png);
    z-index: 2;
    background-position: top center;
    position: absolute;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
    background-repeat: no-repeat;
    width: 700px;
    height: 98vh;
    background-size: 450px;
}


/* END HEADER */

/* INTRO */
#intro-content{
	background-color: #13182c; 
    background: linear-gradient(#13182c, #362d51);

}

#intro-background-bits{
	position: absolute;
    top: -2%;
    height: 102%;
    width: 100%;
    left: 0;
	background-color: transparent;
	opacity: .5;
	background-image: url("../img/Cog_01.png"), url("../img/Cog_02.png"), url("../img/Chain_02.png");
	background-position: top -250px right, bottom 60px left -200px, bottom -80px right -80px;
	background-repeat: no-repeat, no-repeat, no-repeat;
	background-size: auto, auto, auto;
	mix-blend-mode: hard-light;
	filter: blur(4px);
	z-index: 3;
}

#intro-header{
    text-align: left;
	text-transform: uppercase;
}

#video-div{
	position: relative;
	padding-bottom: 56.25%;
	height: 100%;
	min-height:400px;
	overflow: hidden;
	max-width: 100%;
}

iframe{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

#intro-p{
	text-align: left;
	margin: 0 auto;
	justify-content: center;
}
/* END INTRO */

/* ROC VIGNETTE */
#roc-vignette {
	position: relative;
	height:98vh;
	max-height: 1000px;
	width:100%;
	max-width: 100%;
	overflow:hidden;
	display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

#roc-vignette .layer{
	height:1300px;
	min-height: 1300px;
    background-position: top center;
	background-size: auto;
	background-repeat: no-repeat;
	width: 100%;
	position: absolute;
}

#roc-vignette .layer-bg {
	background-color: #362d51;
	background-image: url("../img/roc_Layer_00.png");
	background-position: bottom center;
}

#roc-vignette .layer-1 {
	background-image: url("../img/roc_Layer_01.png");
	background-position: bottom center;
}

#roc-vignette .layer-2 {
	background-image: url("../img/roc_Layer_02.png");
	background-position: bottom left;
}

#roc-vignette .layer-3 {
	background-image: url("../img/roc_Layer_03.png");
	background-position: top 250px right calc(50% - 200px);
    background-size: 700px;
}

#roc-vignette .layer-4 {
	background-image: url("../img/roc_Layer_04.png");
	background-position: bottom 200px left calc(50% - 150px);
    background-size: 500px;
}
/* END ROC VIGNETTE */

/* INFO CONTENT */

#info-background-bits{
	position: absolute;
    top: -2%;
    height: 104%;
    width: 100%;
    left: 0;
	background-color: transparent;
	opacity: .4;
	background-image: url("../img/Chain_02.png"), url("../img/Cog_03.png"), url("../img/Cog_02.png"), url("../img/Nut_01.png"), url("../img/Nut_03.png");
	background-position: top -20px left -60px, top -140px right -40px, bottom -135px left 100px, top 300px right 100px, top 350px left 20px;
	background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
	background-size: 250px, 350px, 275px, 80px, auto;
	z-index: 3;
}

#info-background-shinies{
	position: absolute;
    top: -5%;
    height: 110%;
    width: 100%;
    left: 0;
	background-color: transparent;
	opacity: 1;
	background-image: url("../img/Stars_05.png"), url("../img/Stars_05.png");
	background-position: top left 20px, bottom -15px right -140px;
	background-repeat: no-repeat, no-repeat;
	background-size: 240px, 280px;
	mix-blend-mode: normal;
	z-index: 3;
}

#info-content{
	background: linear-gradient(#564171, #312743);
}

#info-header{
    text-align: left;
	text-transform: uppercase;
}

#info-paragraphs{
	display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
	/* background-color: rgba(255,255,255,0.5); */
}
/* END INFO CONTENT */

/* CAVE VIGNETTE */
#cave-vignette {
	position: relative;
	height:98vh;
	max-height: 1000px;
	width:100%;
	max-width: 100%;
	overflow:hidden;
	display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

#cave-vignette .layer{
	height:1300px;
	min-height: 1300px;
    background-position: top center;
	background-size: auto;
	background-repeat: no-repeat;
	width: 100%;
	position: absolute;
}

#cave-vignette .layer-bg {
	background-color: #032e44;
	background-image: url("../img/Cave_Layer_00.png");
	background-position: center;
}

#cave-vignette .layer-1 {
	background-image: url("../img/Cave_Layer_01.png");
	background-position: top -50px left;
}

#cave-vignette .layer-2 {
	background-image: url("../img/Cave_Layer_02.png");
	background-position: bottom center;
}

#cave-vignette .layer-3 {
	background-image: url("../img/Cave_Layer_03.png");
	background-position: top right -400px
}
/* END CAVE VIGNETTE */

/* TEAM CONTENT */
#team-content{
	background: linear-gradient(#0d0e15, #2f3561);
}

#team-content .w3-row{
	align-items: flex-start;
}

#team-content .w3-col{
	width:33.3%;
	padding-bottom:1%;
}

#team-content .w3-col:first-of-type{
	padding-left:0;
	padding-right:2%;
}

#team-content .w3-col:last-of-type{
	padding-left:2%;
	padding-right:0;
}

.team-header h2{
	color: #fff;
	font-size: 2.3em;
    line-height: 1em;
    margin: 0;
	font-family: Yellowtail, script;
}
/* END TEAM CONTENT */

/* FOOTER CONTENT */

#footer {
	min-height: 98vh;
	overflow: hidden;
	position: relative;
	background: linear-gradient(to bottom, rgb(46, 52, 95) 2%, rgba(46, 52, 95, 0) 10%);
}

#footer a, #footer-mobile a{
	/* font-family: Montebello, sans-serif; */
	/* color: #fc8121; */
	/* font-size: 3.25em;
	text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000; */
	color: #e2e7fc;
	text-transform: lowercase;
}

#footer a:hover, #footer a:active, #footer-mobile a:hover, #footer-mobile a:active{
	/* color: #d65d00; */
	color: #fff;
}

#footer .layer {
	background-position: bottom center;
	background-size: auto;
	background-repeat: no-repeat;
	width: 100%;
	height: 1200px;
	position: absolute;
	z-index: -1;
	bottom: 0;
}

#footer-mobile {
	display: none;
	background: url("../img/Footer_Mobile.png") no-repeat
		center/cover;
	height: 600px;
}

#footer .layer-bg {
	background-color: #2f3561;
	background-image: url("../img/Footer_Layer_00.png");
	background-position: top 170px center;
}

#footer .layer-1 {
	background-image: url("../img/Footer_Layer_01.png");
	background-position: top 100px center;
}

#footer .layer-2 {
	background-image: url("../img/Footer_Layer_02.png");
	background-position: bottom center;
}

#footer .layer-3 {
	background-image: url("../img/Header_Logo.png");
	background-position: top 520px center;
	background-size: 500px;
}

#footer h2, #footer h3, #footer-mobile h2, #footer-mobile h3{
	text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

#footer h2, #footer-mobile h2{
	color:#fc8121;
	font-size: 4.5em;
	line-height: 1em;
	/* margin-bottom: 64px; */
	margin-bottom: 32px;
}

#footer h3, #footer-mobile h3{
	color:#fff;
}

#footer-content.layer{
	z-index: 1;
}

#footer-content .content-div{
	padding: 0;
    position: absolute;
    bottom: 140px;
    text-align: center;
    z-index: 1;
}

.footer-spacer{
	height: 6em;
	display: block;
}

#contact-form input, #contact-form textarea, #contact-form-mobile input, #contact-form-mobile textarea{
   /*  background-color: #272b4f; */
   /*  color: #fff; */
    border: none !important;
    /* border-bottom: 1px solid #fff !important; */
	border-radius: 8px;
	/* padding: 0; */
	height: 100%;
	resize: none;
}

.contact-left-col{
	display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-start;
    align-items: stretch;
    justify-content: flex-start;
}

.contact-group{
	display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}

.contact-input-div{
	flex-grow: 1;
	padding: 8px;
}

.contact-right-col{
	display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: stretch;
    justify-content: flex-start;
	flex-grow: 1;
}

#contact-form .contact-message-div{
	padding: 8px;
	height: 100%;
}

#contact-form button, #contact-form-mobile button{
	padding: 8px; 
	border-radius: 8px;
	min-width: 150px;
    margin-right: 8px;
}

#contact-form-mobile .w3-row{
	display: initial;
}

#contact-form-mobile .s12{
	padding: 0 8px;
}

#contact-form-mobile .contact-message-div{
	height: 100%;
	min-height: 80px;
	padding: 8px 8px 8px 0;
}

#contact-form-mobile textarea{
	min-height: 80px;
}

#contact-form-mobile button{
	margin-right: 16px;
}

#form-overlay, #form-overlay-mobile{
    position: absolute;
    display:none;
    visibility: hidden;
    width: 106%;
    height: 106%;
    left: -3%;
    top: 0;
    background-color: rgb(14 16 24 / 69%);
    z-index: 5;
    border-radius: 8px;
}

#form-feedback, #form-feedback-mobile{
	margin: 0;
	text-align: center;
}

.recaptcha-text{
	font-size: .8em;
    text-align: left;
    margin: 0;
	margin-top: -4px;
    margin-bottom: 4px;
}

.recaptcha-text a{
	font-size: 1em;
	color: initial;
	text-decoration: underline;
}

.grecaptcha-badge { visibility: hidden; }

/* END CONTACT PAGE */

/* SPINNER */
.form-spinner{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    -webkit-animation: lds-dual-ring 1.2s linear infinite;
            animation: lds-dual-ring 1.2s linear infinite;
  }
  @-webkit-keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
/* END SPINNER */

/*END FOOTER CONTENT */

/* @media only screen and (min-width: 2200px){
	#info-content{
		background-size: 25%;
	}
}
 */

 
@media only screen and (min-width: 1921px){
	#header .layer-overlay {
		background:  linear-gradient(90deg, rgb(19, 24, 44) 4%, rgba(19, 24, 44, 0) 10%, rgba(19, 24, 44, 0) 90%, rgb(19, 24, 44) 96%);	
	}

	#roc-vignette .layer-overlay{
		background:linear-gradient(90deg, rgb(194, 214, 238) 4%, rgba(194, 214, 238,0) 10%, rgba(164, 199, 235,0) 90%, rgb(164, 199, 235) 96%);
	}

	#cave-vignette .layer-overlay{
		background:linear-gradient(90deg, rgb(13, 14, 22) 4%, rgba(13, 14, 22,0) 10%, rgba(12, 24, 45,0) 90%, rgb(12, 24, 45) 96%);
	}

	#footer .layer-overlay{
		background:  linear-gradient(90deg, rgba(47,53,97,1) 4%, rgba(47,53,97,0) 10%, rgba(47,53,97,0) 90%, rgba(47,53,97,1) 96%);	
	}
	
	#cave-vignette .layer-1 {
		background-position: top -50px center;
	}
}

@media only screen and (min-width: 2150px){
	#header .layer-overlay {
		background:  linear-gradient(90deg, rgb(19, 24, 44) 10%, rgba(19, 24, 44,0) 14%, rgba(19, 24, 44,0) 86%, rgb(19, 24, 44) 90%);	
	}

	#roc-vignette .layer-overlay{
		background:linear-gradient(90deg, rgb(194, 214, 238) 10%, rgba(194, 214, 238,0) 14%, rgba(164, 199, 235,0) 86%, rgb(164, 199, 235) 90%);
	}

	#cave-vignette .layer-overlay{
		background:linear-gradient(90deg, rgb(13, 14, 22) 10%, rgba(13, 14, 22,0) 14%, rgba(12, 24, 45,0) 86%, rgb(12, 24, 45) 90%);
	}

	#footer .layer-overlay{
		background:  linear-gradient(90deg, rgba(47,53,97,1) 10%, rgba(47,53,97,0) 14%, rgba(47,53,97,0) 86%, rgba(47,53,97,1) 90%);	
	}
	
	#cave-vignette .layer-1 {
		background-position: top -50px center;
	}
}

@media only screen and (max-width: 1536px) {

	#header, #footer, #roc-vignette, #cave-vignette{
		max-height:880px;
	}
	
	#header .layer, #footer .layer{
		background-size: 1536px auto;
		height:1200px;
	}

	.content-div {
		max-width: 800px;
		padding: 2% 0;
	}

	#header .layer-bg {
		background-position: top -215px center;
	}
	
	#header .layer-1 {
		background-position: bottom 100px center;
		background-size: auto 100%;
	}
	
	#header .layer-2 {
		background-position: top -280px center;
		background-size: auto 110%;
	}

	#header .layer-logo {
		background-position: top 30px center;
    	background-size: 500px;
	}
	
	#header .layer-3 {
		background-position: bottom 110px center;
	}
	
	#header .layer-4 {
		background-position: top -200px center;
	}

	#header-logo{
		top: 20px;
		background-size: 400px;
	}

	#video-div{
		margin-bottom:0;
	}

	.w3-row .w3-col.m6:first-of-type{
		padding-right: 2%;
	}

	.w3-row .w3-col.m6:last-of-type{
		padding-left: 2%;
	}

	p{
		/* font-size: 1.1em; */
		font-size: 1.2em;
	}

	#team-content{
		padding-bottom:0;
	}

	#roc-vignette .layer-3 {
		background-position: top 250px right calc(50% - 250px);
	}
	
	#roc-vignette .layer-4 {
		background-position: bottom 250px left calc(50% - 150px);
	}

	#cave-vignette .layer-3 {
		background-position: top right -500px;
	}

	#footer{
		min-height:600px;
		max-height:initial;
		height:98vh;
	}

	#footer-content{
		height: 100%;
	}

	#footer .layer-bg {
		background-position: top 200px  center;
		background-size: cover;
	}

	#footer .layer-1 {
		background-position: top 120px center;
		background-size: 900px auto;
	}

	#footer .layer-2 {
		background-position: bottom center;
	}
	
	#footer .layer-3 {
		background-position: top 55% center;
		background-size: 500px;
	}

	#footer-content .content-div {
		bottom: 125px;
		max-width: 900px;
	}
}

@media only screen and (max-width: 1366px) {
	
	#footer .layer-bg {
		background-position: top 200px center;
	}

	#footer .layer-1 {
		background-position: top 120px center;
		background-size: 900px auto;
	}

	#footer .layer-2 {
		background-position: bottom center;
	}
	
	#footer .layer-3 {
		background-position: top 55% center;
		background-size: 500px;
	}

	#roc-vignette .layer-2 {
		background-position: bottom 50px left;
		background-size: 550px;
	}

}


@media only screen and (max-width: 1280px) {
	.content-div {
		width:100%;
		max-width: 700px;
		padding: 1% 0;
	}

	.content-container{
		min-height:500px;
	}

	p{
		line-height: 1.1em;
    	letter-spacing: .02em;
	}

	#header .layer-bg {
		background-position: bottom 350px center;
	}
	
	#header .layer-1 {
		background-position: bottom 250px center;
		background-size: auto 100%;
	}
	
	#header .layer-2 {
		background-position: top -370px center;
		background-size: auto 110%;
	}

	#header .layer-logo {
		background-position: top 20px center;
		background-size: 450px;
	}
	
	#header .layer-3 {
		background-position: bottom 250px center;
	}
	
	#header .layer-4 {
		background-position: bottom 200px center;
	}

	#header-logo{
		top: 10px;
		background-size: 375px;
	}
	
	#roc-vignette .layer-3 {
		background-position: top 250px right calc(50% - 200px);
		background-size: 650px;
	}
	
	#roc-vignette .layer-4 {
		background-position: bottom 250px left calc(50% - 150px);
		background-size: 500px;
	}

	#cave-vignette .layer-1 {
		background-position: top -50px left -300px;
	}

	#cave-vignette .layer-2 {
		background-position: bottom left calc(50% - 50px);
	}

	#cave-vignette .layer-3 {
		background-position: top 35px right -500px;
	}

	#footer{
		min-height:500px;
		height:99vh;
	}

	#footer h2{
		margin-bottom: 32px;
	}

	#footer .layer-bg {
		background-position: top 100px center;
	}

	#footer .layer-1 {
		background-position: top 150px center;
    	background-size: 900px auto;
	}

	#footer .layer-2 {
		background-position: bottom center;
	}
	
	#footer .layer-3 {
		background-position: center calc(50% + 70px);
    	background-size: 450px;
	}

}

@media only screen and (max-width: 1024px) {
	#cave-vignette .layer-bg {
		background-position: top center;
	}
	
	#cave-vignette .layer-1 {
		background-position: top left -350px;
		background-size: auto 100%;
	}
	
	#cave-vignette .layer-2 {
		background-position: bottom right calc(50% + 150px);
    	background-size: auto;
	}
	
	#cave-vignette .layer-3 {
		background-position: top 25px right -500px;
	}

	#roc-vignette .layer-3 {
		background-position: top 280px right calc(50% - 150px);
		background-size: 600px;
	}
	
	#roc-vignette .layer-4 {
		background-position: bottom 340px left calc(50% - 150px);
		background-size: 420px;
	}

	#header, #roc-vignette, #cave-vignette {
		max-height: 700px;
	}

	#info-background-shinies{
		background:none;
	}
}

@media only screen and (max-width: 1024px) and (orientation: portrait) {
	#header, #roc-vignette, #cave-vignette{
		max-height:780px;
	}
	
	#footer{
		height:880px;
		max-height:880px;
		min-height: 880px;
	}

	#footer .layer{
		max-height:880px;
		min-height:880px;
		height: 880px;
		/* bottom:15%; */
	}

	#footer h2{
		margin-bottom: 40px;
	}

	#footer .layer-bg {
		background-position: top -60px center;
    	background-size: auto;
	}

	#footer .layer-1 {
		background-position: top -50px center;
		background-size: 900px auto;
	}

	#footer .layer-2 {
		background-position: bottom center;	
	}
	
	#footer .layer-3 {
		background-position: top 260px center;
    	background-size: 450px;
	}

	#footer-content .content-div {
		position:relative;
		bottom:auto;
		max-width: 900px;
		margin-top: 410px;
	}

}

/* W3 CSS medium breakpoint */
@media only screen and (max-width: 992px) {

	.content-div {
		max-width: 650px;
		padding: 1% 0;
	}

	p {
		font-size: 1.1em;
		letter-spacing: 0.02em;
		line-height: 1.1em;
	}

	#info-content .w3-row,
	#team-content .w3-row{
		display: block;
	}
}


@media only screen and (max-width: 992px) and (max-height: 500px){
	#header-mobile{
		background-image: url("../img/Header_Mobile_v2.png");
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}

	.show-on-mobile{
		display:block;
		height:initial;
		visibility: visible;
	}

	.hide-on-mobile{
		display:none;
		height:0;
		visibility: hidden;
	}

	#header, #footer, #header-logo{
		display:none;
		height:0;
		visibility: hidden;
	}

	.layer{
		display:none;
		height:0;
		visibility: hidden;
	}

	#header-mobile{
		display: block;
		height: 96vh;
		min-height: initial;
		max-height: initial;
		visibility: visible;
		width: 100%;
		position: relative;
		overflow-anchor: none;
	}

	#footer-mobile{
		display: block;
		height: initial;
		min-height: 600px;
		max-height: initial;
		visibility: visible;
		width: 100%;
		position: relative;
	}

	.content-container {
		min-height: 500px;
		padding: 4% 0;
	}

	.content-div{
		max-width: initial;
		width:96%;
	}
	
	.img-border {
		min-height: 10px;
	}

	.img-border-top img, .img-border-bottom img{
		width:100%;
	}

	#roc-vignette, #cave-vignette {
		max-height: 600px;
		height: initial;
	}

	#cave-vignette-mobile, #roc-vignette-mobile{
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		align-content: center;
		justify-content: center;
		align-items: stretch;
		min-height:100%;
	}

	#cave-vignette-mobile img, #roc-vignette-mobile img{
		height: auto;
		width: 100%;
	}

	#intro-content{
		padding-bottom:8%;
	}

	#team-content, #intro-content{
		padding-top:6%;
	}

	.w3-row .w3-col.m6:first-of-type {
		padding-right: 1%;
	}

	.w3-row .w3-col.m6:last-of-type {
		padding-right: 1%;
	}

	#team-content .w3-col{
		width:100%;
		padding-bottom: 4%;
	}

	#team-content .w3-col:first-of-type, #team-content .w3-col:last-of-type {
		padding-left: 0;
		padding-right: 0;
	}

	#footer-mobile .content-container{
		height: 100%;
		justify-content: flex-end;
		width: 100%;
		text-align: center;
		padding-bottom:2%;
		min-height: 900px;
	}

	#footer-mobile .content-div{
		max-width: initial;
		width:96%;
	}

/* 	#footer-mobile a{
		font-size: 2.4em;
	} */
	
}


/* Mobile devices START HERE - Parallax disabled. */
@media only screen and (max-width: 768px) {
/* 	html {
		scroll-behavior: initial;
	} */
	
	.show-on-mobile{
		display:block;
		height:initial;
		visibility: visible;
	}

	.hide-on-mobile{
		display:none;
		height:0;
		visibility: hidden;
	}

	#header, #footer, #header-logo{
		display:none;
		height:0;
		visibility: hidden;
	}

	.layer{
		display:none;
		height:0;
		visibility: hidden;
	}

	#header-mobile{
		display: block;
		height: 96vh;
		min-height: initial;
		max-height: initial;
		visibility: visible;
		width: 100%;
		position: relative;
		overflow-anchor: none;
	}

	#footer-mobile{
		display: block;
		height: initial;
		min-height: 600px;
		max-height: initial;
		visibility: visible;
		width: 100%;
		position: relative;
	}

	.content-container {
		min-height: 500px;
		padding: 4% 0;
	}

	.content-div{
		max-width: initial;
		width:96%;
	}
	
	.img-border {
		min-height: 10px;
	}

	.img-border-top img, .img-border-bottom img{
		width:100%;
	}

	#roc-vignette, #cave-vignette {
		max-height: 600px;
		height: initial;
	}

	#cave-vignette-mobile, #roc-vignette-mobile{
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		align-content: center;
		justify-content: center;
		align-items: stretch;
		min-height:100%;
	}

	#cave-vignette-mobile img, #roc-vignette-mobile img{
		height: auto;
		width: 100%;
	}

	#intro-content{
		padding-bottom:8%;
	}

	#team-content, #intro-content{
		padding-top:6%;
	}

	.w3-row .w3-col.m6:first-of-type {
		padding-right: 1%;
	}

	.w3-row .w3-col.m6:last-of-type {
		padding-right: 1%;
	}

	#team-content .w3-col{
		width:100%;
		padding-bottom: 4%;
	}

	#team-content .w3-col:first-of-type, #team-content .w3-col:last-of-type {
		padding-left: 0;
		padding-right: 0;
	}

	#footer-mobile .content-container{
		height: 100%;
		justify-content: flex-end;
		width: 100%;
		text-align: center;
		padding-bottom:2%;
		min-height: 1100px;
	}

	#footer-mobile .content-div{
		max-width: initial;
		width:96%;
	}

	.recaptcha-text{
		padding: 0 8px;
	}

	#form-feedback-mobile{
		margin-top: 4px;
	}

/* 	#footer-mobile a{
		font-size: 2.4em;
	}
	 */
}

@media only screen and (max-width: 768px) and (orientation: landscape){
	#header-mobile{
		background-image: url("../img/Header_Mobile_v2.png");
		background-size: auto;
		background-position: center;
		background-repeat: no-repeat;
	}
}

@media only screen and (max-width: 601px) {
	h1{
		font-size: 3em;
	}

	.team-header h2 {
		font-size:2em;
	}

	.team-header h2:first-of-type{
		padding-top:2%;
	}

	.content-container{
		min-height: initial;
	}
	
	#intro-content .content-div{
		width:100%;
	}

	#intro-content .w3-row{
		width:96%;
		margin-left:2%;
	}

	#intro-content .w3-row:nth-of-type(2){
		width:100%;
		margin-left:0;
	}

	#intro-content .w3-row:last-of-type .w3-col:first-of-type p:last-of-type,
	#info-content .w3-row:last-of-type .w3-col:first-of-type p:last-of-type  {
		margin-bottom: 0;
	}

	#intro-content .w3-row {
		display: block;
	}

	#intro-content .w3-col{
		width: 100%;
	}

	.w3-row .w3-col.m6:first-of-type{
		padding-right:0;
	}

	.w3-row .w3-col.m6:last-of-type{
		padding-left:0;
	}

	#footer-mobile .content-div{
		max-width: initial;
		width:100%;
	}

	#footer-mobile .content-container{
		padding-bottom: 2%;
		min-height: 900px;
	}

	#footer-mobile h3{
		padding: 0 4%;
	}

	#video-div{
		width:100%;
		min-height: 240px;
	}

	#roc-vignette, #cave-vignette {
		max-height: 600px;
		height: initial;
	}
}

@media only screen and (max-width: 420px) {


	h1{
		font-size: 2em;
	}

	.team-header h2{
		font-size: 1.8em;
	}

	
	#footer-mobile h3{
		padding: 0;
		font-size: 1.6em;
	}

/* 	#footer-mobile a {
		font-size: 2.1em;
	} */

}

@media only screen and (max-width: 360px) {

	#footer-mobile .content-container{
		padding-bottom: 0;
	}

	#footer-mobile h3{
		padding: 0 2%;
	}

/* 	#footer-mobile a {
		font-size: 2em;
	} */
}

@media only screen and (max-width: 320px) {
	html, body{
		overscroll-behavior-y: initial;
	}
	h1{
		font-size: 3em;
	}

	#team-content h1{
		padding-right:20px;
	}

/* 	#footer-mobile a {
		font-size: 1.75em;
	} */
}